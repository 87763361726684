import styled from "styled-components/macro";
import * as GlobalVariables from "../Styles/GlobalVariables";

const FooterStyled = styled.div`
  background: black;
  letter-spacing: 3px;
  width: 100%;
  color: white;
  text-align: center;
`;

const Footer = (props) => {
  return (
    <FooterStyled className="vertical-padding-small">
      ©COPYRIGHT AAQILL NOORAMITH
    </FooterStyled>
  );
};

export default Footer;
