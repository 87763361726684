import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import LazyLoad from "react-lazy-load";
import * as GlobalVariables from "../Styles/GlobalVariables";
// import ContactFormMedia from "../Components/ContactFormMedia";
import mixpanel from "mixpanel-browser";
import { InView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive"; // A must for detecting responsivity

import { Parallax } from "react-scroll-parallax";
import BlackSection from "../Components/BlackSection";
import ImageDivider from "../Components/ImageDivider";
import ScrollDownArrow from "../Components/ScrollDownArrow";

//IMAGES
import background from "../Images/opening-bg.webp";
import openingGraphic from "../Images/opening-graphic.svg";
import openingImageLarge from "../Images/opening-image-large.webp";
import openingImageMedium from "../Images/opening-image-medium.webp";
import openingImage from "../Images/opening-image-mob.webp";

import quoteMark from "../Images/quote-mark.svg";

import shrineLarge from "../Images/photos/shrine-high-ppi.webp";
import shrine from "../Images/photos/shrine-low-ppi.webp";

import barberLarge from "../Images/photos/barber-large.webp";
import barber from "../Images/photos/barber-small.webp";

import templeLarge from "../Images/photos/temple-large.webp";
import temple from "../Images/photos/temple-small.webp";

import girlLarge from "../Images/photos/girl-large.webp";
import girlMedium from "../Images/photos/girl-medium.webp";
import girl from "../Images/photos/girl-small.webp";

import landscapeLarge from "../Images/photos/nature-landscape-large.webp";
import landscapeMedium from "../Images/photos/nature-landscape-medium.webp";
import landscape from "../Images/photos/nature-landscape-small.webp";

import sageLarge from "../Images/photos/sage-large.webp";
import sage from "../Images/photos/sage-small.webp";

import temple2Large from "../Images/photos/temple2-large.webp";
import temple2 from "../Images/photos/temple2-small.webp";

import potMakerLarge from "../Images/photos/pot-maker-large.webp";
import potMakerMedium from "../Images/photos/pot-maker-medium.webp";
import potMaker from "../Images/photos/pot-maker-small.webp";

import rugsLarge from "../Images/photos/rugs-large.webp";
import rugsMedium from "../Images/photos/rugs-medium.webp";
import rugs from "../Images/photos/rugs-small.webp";

import civLarge from "../Images/photos/civ-large.webp";
import civ from "../Images/photos/civ-small.webp";

import potMaker2Large from "../Images/photos/potmaker2-large.webp";
import potMaker2 from "../Images/photos/potmaker2-small.webp";

import kidLarge from "../Images/photos/kid-large.webp";
import kid from "../Images/photos/kid-small.webp";

import loversPerchLarge from "../Images/photos/loversperch-large.webp";
import loversPerch from "../Images/photos/loversperch-small.webp";

import camelLarge from "../Images/photos/camel-large.webp";
import camelMedium from "../Images/photos/camel-medium.webp";
import camel from "../Images/photos/camel-small.webp";

import ColourSquare from "../Components/ColourSqare";
import ColourSquareContainer from "../Components/ColourSqareContainer";

import ContactFormMedia from "../Components/ContactFormMedia";
const urlEndpoint = "https://ik.imagekit.io/kx65wqg4n/";

const testbg =
  "https://ik.imagekit.io/kx65wqg4n/73cea883fe0a152fef26aaab2abf05b6.webp";
// const ContactFormMedia = lazy(() => import("../Components/ContactFormMedia"));

const HomeWrapper = styled.div`
  @media ${GlobalVariables.device.tablet} {
    text-align: center;
  }
  @media ${GlobalVariables.device.laptop} {
    text-align: left;
  }
  .intro-section,
  .section2 {
    img {
      margin-top: 2em;
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }
  .offset-inview {
    margin-top: -19em;

    margin-bottom: 18.9em;
    z-index: -1;
    position: relative;
  }
  .section2 {
    @media ${GlobalVariables.device.laptop} {
      display: none;
    }
  }

  .intro-section {
    position: relative;
    .desktop-text-container {
      display: none;
    }
    @media ${GlobalVariables.device.laptop} {
      .desktop-text-container {
        width: 26em;
        position: absolute;
        display: block;
        left: 56vw;
        bottom: -26em;

        display: block;
        > * {
          display: block;
        }
      }
      display: flex;
      height: 78vw;
      justify-content: space-between;
      align-items: center;
      h2,
      p {
        display: none;
      }
      img {
        height: 100%;
        margin-top: 0;
      }
      picture {
        display: block;
      }
      > .image-container {
        height: 100%;
        flex: 1 1 50%;
        picture {
          height: 100%;
        }
        img {
          object-fit: cover;
        }
      }
      .left-column-desktop {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 0 1 39%;
        margin-right: 2em;
        .image-container:first-of-type {
          height: 55%;

          picture {
            height: 100%;
          }
        }
        .image-container:last-of-type {
          margin-top: 3em;
          flex: 1 1 auto;
          picture {
            height: 100%;
          }
        }
      }
    }
    .image-container.desktop-visible {
      display: none;
      @media ${GlobalVariables.device.laptop} {
        display: block;
      }
    }
  }
  .desktop-intro-container,
  .desktop-text-container {
    display: none;
    padding: 2.7em 2.7em;
    background: white;
  }
  .parallax-container {
  }
  .desktop-intro-container {
    position: absolute;

    left: -78vw;
    top: calc(32vw * 0.5);
    bottom: initial;
    width: 23em;

    @media ${GlobalVariables.device.laptop} {
      display: block;
    }
  }

  .full-width-image-section {
    padding-bottom: 0;
    .image-container {
      position: relative;
      display: flex;
      justify-content: center;
    }
    img {
      width: 100%;
      display: block;
    }
    q {
      font-family: "Abhaya Libre", sans-serif;
      font-weight: bold;
      position: absolute;
      background: white;
      padding: 0.5em 1em;
      bottom: 20%;
      text-transform: capitalize;
      @media ${GlobalVariables.device.laptop} {
        font-size: 1.2em;
        bottom: 15%;
      }
    }
  }

  .white-section {
    @media ${GlobalVariables.device.laptop} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 3em;
      grid-row-gap: 0px;
    }
  }
  .vertical-line {
    width: 2px;
    height: 2em;
    background: white;
    margin-left: auto;
    margin-right: auto;
    @media ${GlobalVariables.device.laptop} {
      display: none;
    }
  }

  video.zoom-video {
    width: 100%;
    display: block;
  }
`;

const PictureFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.6em;
`;

const OpeningSectionWrapper = styled.div`
  height: 140vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${GlobalVariables.device.laptop} {
    height: 200vh;

    background: url(${background}) no-repeat top center;
    background-size: contain;
  }
  background-size: cover;
  position: relative;
  .inner-container {
    height: 100%;
    width: 100%;
    position: relative;
    background: url(${openingImage}) no-repeat top center;

    .desktop-background-container {
      display: none;
      height: 100%;
    }
    @media ${GlobalVariables.device.tablet} {
      background: url(${openingImageMedium}) no-repeat top center;
      background-size: cover;
    }
    @media ${GlobalVariables.device.laptop} {
      background: none;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    img.logo {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      display: block;
      @media ${GlobalVariables.device.tablet} {
        width: 70%;
      }
      @media ${GlobalVariables.device.laptop} {
        width: 26vw;
        position: absolute;
        right: -6em;
        bottom: 17em;
      }
    }
    img.arrows {
      position: absolute;
      width: 46px;
      bottom: 35%;
    }

    @media ${GlobalVariables.device.laptop} {
      .desktop-background-container {
        display: block;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .text-container {
    background: black;
    color: white;
    padding: 2rem;
    border-radius: 4px;
  }
  .colors-container {
    position: absolute;
    bottom: -2%;
  }
`;

const PhotoWithCaption = styled.div`
  margin-bottom: 3em;
  &:last-of-type {
    margin-bottom: 0;
  }
  img {
    width: 100%;
  }
`;

const Home = () => {
  // const [isContainerVisisble, setVisibility] = useState(false);
  // const [isDividerVisible, setDividerVisibility] = useState(false);
  const [isFullWidthImageSectionVisible, setFullWidthImageSectionVisible] =
    useState(false);
  const [isIntroSectionVisisble, setIntroSectionVisisble] = useState(false);
  const [isBlackSectionVisible, setBlackSectionVisibiilty] = useState(false);
  const [isWhiteAndContactSectionVisible, setWhiteAndContactSectionVisibility] =
    useState(false);
  const containerRef = useRef(null); // useRef is used here as an alternative to querySelectorAll as it works better for single elements
  const dividercontainerRef = useRef(null); // useRef is used here as an alternative to querySelectorAll as it works better for single elements

  // const observer = useMemo(() => {
  //   return new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         setVisibility(true);

  //         observer.unobserve(entry.target);
  //       }
  //     });
  //   });
  // }, []);

  // const dividerobserver = useMemo(() => {
  //   return new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         setDividerVisibility(true);

  //         dividerobserver.unobserve(entry.target);
  //       }
  //     });
  //   });
  // }, []);

  useEffect(() => {
    mixpanel.init("e3ad3baaa3b0585cc33894cfad7bccc5", {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });

    // Set this to a unique identifier for the user performing the event.
    mixpanel.identify(/* \"<USER_ID\"> */);

    // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
    mixpanel.track("Loaded", {
      "Type of Load": "useEffect component load",
    });
    // observer.observe(containerRef.current);
    // dividerobserver.observe(dividercontainerRef.current);

    // Return a cleanup function to disconnect the observer
    return () => {
      // observer.disconnect();
      // dividerobserver.disconnect();
    };
  }, []);

  const isDesktop = useMediaQuery({
    query: `${GlobalVariables.device.laptop}`,
  });

  return (
    <HomeWrapper>
      <OpeningSectionWrapper>
        <div className="inner-container">
          <div className="desktop-background-container desktop-inner-grid">
            {isDesktop && (
              <picture>
                <source
                  sizes="70vw"
                  srcSet="https://ik.imagekit.io/kx65wqg4n/73cea883fe0a152fef26aaab2abf05b6.webp?tr=w-730 730w,
                   https://ik.imagekit.io/kx65wqg4n/73cea883fe0a152fef26aaab2abf05b6.webp?tr=w-1024 1024w,
                   https://ik.imagekit.io/kx65wqg4n/73cea883fe0a152fef26aaab2abf05b6.webp?tr=w-1250 1250w,
                   https://ik.imagekit.io/kx65wqg4n/73cea883fe0a152fef26aaab2abf05b6.webp?tr=w-1442 1442w,
                   https://ik.imagekit.io/kx65wqg4n/73cea883fe0a152fef26aaab2abf05b6.webp?tr=w-1620 1620w,
                   https://ik.imagekit.io/kx65wqg4n/73cea883fe0a152fef26aaab2abf05b6.webp?tr=w-1770 1770w"
                />
                <img rel="preload" src={openingImageLarge} alt="Friends" />
              </picture>

              // <img src={testbg} srcSet={`${testbg} 730w`} alt="Friends" />
            )}
          </div>
          <Parallax disabled={!isDesktop} speed={10}>
            <img
              className="logo"
              rel="preload"
              src={openingGraphic}
              alt="Logo"
            />
          </Parallax>

          <div className="parallax-container">
            <Parallax disabled={!isDesktop} speed={15}>
              <div className="desktop-intro-container">
                <h2>Welcome</h2>
                <p>
                  Welcome to Aaqil's Photography, where every picture tells a
                  story. Aaqil captures life's moments with an eye for detail
                  and a passion for authenticity, creating timeless images that
                  will be treasured for years to come.
                </p>
              </div>
            </Parallax>
          </div>

          <ScrollDownArrow></ScrollDownArrow>
        </div>
      </OpeningSectionWrapper>

      {/* INTRO SECTION */}
      <InView
        as="div"
        className="offset-inview"
        triggerOnce={true}
        onChange={(inView, entry) => setIntroSectionVisisble(inView)}
      ></InView>

      <>
        <div className="outer-grid tablet-inner-grid vertical-padding-large desktop-vertical-padding-small desktop-inner-grid intro-section">
          <h2>Welcome</h2>
          <p>
            Welcome to Aaqil's Photography, where every picture tells a story.
            Aaqil captures life's moments with an eye for detail and a passion
            for authenticity, creating timeless images that will be treasured
            for years to come.
          </p>
          <Parallax disabled={!isDesktop} speed={15}>
            <div className="desktop-text-container">
              <h2>Stepping out of the comfort zone</h2>
              <p>
                Aaqil fearlessly captures the essence of city life as well as
                the essence of nature through his lens, unapologetically
                exploring the raw and unfiltered reality of the streets as a
                passionate and talented photographer.
              </p>
            </div>
          </Parallax>

          <div className="left-column-desktop">
            <div className="image-container">
              <LazyLoad offset={500}>
                <picture>
                  <source
                    sizes="(min-width: 1040px) 27.55vw, (min-width: 780px) calc(100vw - 280px), (min-width: 540px) 460px, 90.91vw"
                    srcSet={`${shrine} 767w,
            ${shrineLarge} 1095w,
            https://ik.imagekit.io/kx65wqg4n/309fc1f7865cbdb10617c7d49c46877c.webp?tr=w-460 460w,
            https://ik.imagekit.io/kx65wqg4n/309fc1f7865cbdb10617c7d49c46877c.webp?tr=w-920 920w,
            https://ik.imagekit.io/kx65wqg4n/309fc1f7865cbdb10617c7d49c46877c.webp?tr=w-1210 1210w,
            https://ik.imagekit.io/kx65wqg4n/309fc1f7865cbdb10617c7d49c46877c.webp?tr=w-1450 1450w,
            https://ik.imagekit.io/kx65wqg4n/309fc1f7865cbdb10617c7d49c46877c.webp?tr=w-1650 1650w,
            `}
                  />
                  <img
                    width="360"
                    height="640"
                    src={shrineLarge}
                    alt="Shrine"
                  />
                </picture>
              </LazyLoad>

              <PictureFooterContainer>
                <ColourSquareContainer>
                  <ColourSquare color="#C6994A" />
                  <ColourSquare color="#687F78" />
                  <ColourSquare color="#EFE9E5" />
                </ColourSquareContainer>
                <h4>Shrine/India</h4>
              </PictureFooterContainer>
            </div>

            <div className="image-container desktop-visible">
              <LazyLoad offset={500}>
                <picture>
                  <source
                    sizes="27.55vw"
                    srcSet={`${temple} 702w, ${templeLarge} 1002w`}
                  />
                  <img src={templeLarge} alt="Temple" />
                </picture>
              </LazyLoad>

              <PictureFooterContainer>
                <ColourSquareContainer>
                  <ColourSquare color="#233923" />
                  <ColourSquare color="#D6BE92" />
                  <ColourSquare color="#E8E529" />
                </ColourSquareContainer>
                <h4>Shrines/India</h4>
              </PictureFooterContainer>
            </div>
          </div>

          <div className="image-container">
            <LazyLoad offset={500}>
              <picture>
                <source
                  sizes="(min-width: 1040px) calc(43.06vw - 32px), (min-width: 780px) calc(100vw - 280px), (min-width: 540px) 460px, 90.91vw"
                  srcSet={`${barber} 561w,
        ${barberLarge} 982w,
        https://ik.imagekit.io/kx65wqg4n/c571f937a827d85c980231d4cd821f2d.webp?tr=w-460 460w,
        https://ik.imagekit.io/kx65wqg4n/c571f937a827d85c980231d4cd821f2d.webp?tr=w-920 920w,
        https://ik.imagekit.io/kx65wqg4n/c571f937a827d85c980231d4cd821f2d.webp?tr=w-1260 1260w,
        https://ik.imagekit.io/kx65wqg4n/c571f937a827d85c980231d4cd821f2d.webp?tr=w-1500 1500w,
        https://ik.imagekit.io/kx65wqg4n/c571f937a827d85c980231d4cd821f2d.webp?tr=w-1700 1700w,
        https://ik.imagekit.io/kx65wqg4n/c571f937a827d85c980231d4cd821f2d.webp?tr=w-1880 1880w,
        https://ik.imagekit.io/kx65wqg4n/c571f937a827d85c980231d4cd821f2d.webp?tr=w-2048 2048w
  
        `}
                />
                <img width="360" height="640" src={barberLarge} alt="Barber" />
              </picture>
            </LazyLoad>

            <PictureFooterContainer>
              <ColourSquareContainer>
                <ColourSquare color="#327D45" />
                <ColourSquare color="#042211" />
                <ColourSquare color="#B1C9C6" />
              </ColourSquareContainer>
              <h4>Barber/India</h4>
            </PictureFooterContainer>
          </div>
        </div>
        <div className="outer-grid tablet-inner-grid vertical-padding-normal desktop-inner-grid section2 no-top">
          <h2>Stepping out of the comfort zone</h2>
          <p>
            Aaqil fearlessly captures the essence of city life as well as the
            essence of nature through his lens, unapologetically exploring the
            raw and unfiltered reality of the streets as a passionate and
            talented photographer.
          </p>

          <LazyLoad offset={500}>
            <picture>
              <source
                sizes="(min-width: 780px) calc(100vw - 280px), (min-width: 540px) 460px, 90.91vw"
                srcSet={`${temple} 702w, ${templeLarge} 1002w`}
              />
              <img src={templeLarge} alt="Temple" />
            </picture>
          </LazyLoad>
          <PictureFooterContainer>
            <ColourSquareContainer>
              <ColourSquare color="#233923" />
              <ColourSquare color="#D6BE92" />
              <ColourSquare color="#E8E529" />
            </ColourSquareContainer>
            <h4>Shrines/India</h4>
          </PictureFooterContainer>
        </div>
      </>

      {/* FULL WIDTH IMAGE SECTION */}
      <InView
        as="div"
        className="offset-inview"
        triggerOnce={true}
        onChange={(inView, entry) => setFullWidthImageSectionVisible(inView)}
      ></InView>

      <div className="full-width-image-section vertical-padding-normal desktop-vertical-padding-large">
        <div className="image-container">
          <LazyLoad offset={500}>
            <picture>
              <source
                sizes="100vw"
                srcSet={`${girl} 1020w,
          ${girlMedium} 1500w,
          ${girlLarge} 1920w,
          https://ik.imagekit.io/kx65wqg4n/69cecb8823ef89e2da2b6ebf5b48f7e0.webp?tr=w-300 300w,
          https://ik.imagekit.io/kx65wqg4n/69cecb8823ef89e2da2b6ebf5b48f7e0.webp?tr=w-880 880w,
          https://ik.imagekit.io/kx65wqg4n/69cecb8823ef89e2da2b6ebf5b48f7e0.webp?tr=w-1210 1210w,
          https://ik.imagekit.io/kx65wqg4n/69cecb8823ef89e2da2b6ebf5b48f7e0.webp?tr=w-1470 1470w,
          https://ik.imagekit.io/kx65wqg4n/69cecb8823ef89e2da2b6ebf5b48f7e0.webp?tr=w-1680 1680w,
          https://ik.imagekit.io/kx65wqg4n/69cecb8823ef89e2da2b6ebf5b48f7e0.webp?tr=w-1870 1870w,
          https://ik.imagekit.io/kx65wqg4n/69cecb8823ef89e2da2b6ebf5b48f7e0.webp?tr=w-2048 2048w,
          `}
              />
              <img src={girlMedium} alt="Girl looking out window" />
            </picture>
          </LazyLoad>
          <q>Photographer’s capture the essence of life</q>
        </div>

        <div className="image-container">
          <LazyLoad offset={500}>
            <picture>
              <source
                sizes="100vw"
                srcSet={`${landscape} 1020w,
          ${landscapeMedium} 1500w,
          ${landscapeLarge} 1920w,
          https://ik.imagekit.io/kx65wqg4n/ab0862724eaf91312dee7ec848071e21.webp?tr=w-300 300w,
          https://ik.imagekit.io/kx65wqg4n/ab0862724eaf91312dee7ec848071e21.webp?tr=w-880 880w,
          https://ik.imagekit.io/kx65wqg4n/ab0862724eaf91312dee7ec848071e21.webp?tr=w-1210 1210w,
          https://ik.imagekit.io/kx65wqg4n/ab0862724eaf91312dee7ec848071e21.webp?tr=w-1470 1470w,
          https://ik.imagekit.io/kx65wqg4n/ab0862724eaf91312dee7ec848071e21.webp?tr=w-1680 1680w,
          https://ik.imagekit.io/kx65wqg4n/ab0862724eaf91312dee7ec848071e21.webp?tr=w-1870 1870w,
          https://ik.imagekit.io/kx65wqg4n/ab0862724eaf91312dee7ec848071e21.webp?tr=w-2048 2048w,
          `}
              />
              <img src={landscapeMedium} alt="Landscape" />
            </picture>
          </LazyLoad>
          <q>And the mysteries of nature</q>
        </div>
      </div>

      <InView
        as="div"
        className="offset-inview"
        triggerOnce={true}
        onChange={(inView, entry) => setBlackSectionVisibiilty(inView)}
      ></InView>
      {/* BLACK SECTION */}

      <BlackSection>
        <div className="first-section outer-grid desktop-inner-grid tablet-inner-grid vertical-padding-normal">
          <div className="image-container">
            <LazyLoad offset={500}>
              <picture>
                <source
                  sizes="(min-width: 1040px) 33.93vw, (min-width: 780px) calc(100vw - 280px), (min-width: 540px) 460px, 90.91vw"
                  srcSet={`${sage} 768w,
                ${sageLarge} 1020w,
                https://ik.imagekit.io/kx65wqg4n/90614d1ab04410f09bd6519f83f73ef4.webp?tr=w-460 460w,
                https://ik.imagekit.io/kx65wqg4n/90614d1ab04410f09bd6519f83f73ef4.webp?tr=w-920 920w,
                https://ik.imagekit.io/kx65wqg4n/90614d1ab04410f09bd6519f83f73ef4.webp?tr=w-1230 1230w,
                https://ik.imagekit.io/kx65wqg4n/90614d1ab04410f09bd6519f83f73ef4.webp?tr=w-1470 1470w,
                https://ik.imagekit.io/kx65wqg4n/90614d1ab04410f09bd6519f83f73ef4.webp?tr=w-1680 1680w,
                https://ik.imagekit.io/kx65wqg4n/90614d1ab04410f09bd6519f83f73ef4.webp?tr=w-1860 1860w,
                https://ik.imagekit.io/kx65wqg4n/90614d1ab04410f09bd6519f83f73ef4.webp?tr=w-2030 2030w
                `}
                />
                <img src={sageLarge} alt="Sage" />
              </picture>
            </LazyLoad>
            <div className="vertical-line"></div>
            <Parallax
              rootMargin={{ top: 300, right: 100, bottom: 0, left: 100 }}
              disabled={!isDesktop}
              speed={10}
            >
              <div className="quote-container">
                <img src={quoteMark} alt="Quote mark" />
                <p className="quote">
                  "In time we will tell our stories to the world about our daily
                  living and small joys"
                </p>
              </div>
            </Parallax>
          </div>

          <div className="image-container desktop-right">
            <picture>
              <source
                sizes="(min-width: 1040px) 33.93vw, (min-width: 780px) calc(100vw - 280px), (min-width: 540px) 460px, 90.91vw"
                srcSet={`${temple2} 768w,
                ${temple2Large} 1020w,
                https://ik.imagekit.io/kx65wqg4n/e97ff4fef87823247fe45fff0b3c2184.webp?tr=w-460 460w,
                https://ik.imagekit.io/kx65wqg4n/e97ff4fef87823247fe45fff0b3c2184.webp?tr=w-920 920w,
                https://ik.imagekit.io/kx65wqg4n/e97ff4fef87823247fe45fff0b3c2184.webp?tr=w-1230 1230w,
                https://ik.imagekit.io/kx65wqg4n/e97ff4fef87823247fe45fff0b3c2184.webp?tr=w-1470 1470w,
                https://ik.imagekit.io/kx65wqg4n/e97ff4fef87823247fe45fff0b3c2184.webp?tr=w-1680 1680w,
                https://ik.imagekit.io/kx65wqg4n/e97ff4fef87823247fe45fff0b3c2184.webp?tr=w-1860 1860w,
                https://ik.imagekit.io/kx65wqg4n/e97ff4fef87823247fe45fff0b3c2184.webp?tr=w-2030 2030w
                `}
              />
              <img src={temple2Large} alt="Templ 2" />
            </picture>

            <div className="vertical-line"></div>
            <Parallax
              rootMargin={{ top: 300, right: 100, bottom: 0, left: 100 }}
              disabled={!isDesktop}
              speed={20}
            >
              <div className="quote-container second">
                <img src={quoteMark} alt="Quote mark" />
                <p className="quote">
                  Amidst the bustle of life, civilization thrives, <br />
                  <br />
                  A tapestry woven with humanity's lives,
                  <br />
                  <br />
                  From towering skyscrapers to humble abodes,
                  <br />
                  <br />A melting pot of cultures and stories untold.
                </p>
              </div>
            </Parallax>
          </div>
        </div>

        <div className="full-width-image-section2 vertical-padding-normal">
          <div className="image-container">
            <LazyLoad offset={500}>
              <picture>
                <source
                  sizes="100vw"
                  srcSet={`${potMaker} 1020w,
                  ${potMakerMedium} 1500w,
                  ${potMakerLarge} 1920w,
                  https://ik.imagekit.io/kx65wqg4n/086b54aa7ff671f151ca7bd850b0f215.webp?tr=w-300 300w,
                  https://ik.imagekit.io/kx65wqg4n/086b54aa7ff671f151ca7bd850b0f215.webp?tr=w-880 880w,
                  https://ik.imagekit.io/kx65wqg4n/086b54aa7ff671f151ca7bd850b0f215.webp?tr=w-1210 1210w,
                  https://ik.imagekit.io/kx65wqg4n/086b54aa7ff671f151ca7bd850b0f215.webp?tr=w-1470 1470w,
                  https://ik.imagekit.io/kx65wqg4n/086b54aa7ff671f151ca7bd850b0f215.webp?tr=w-1680 1680w,
                  https://ik.imagekit.io/kx65wqg4n/086b54aa7ff671f151ca7bd850b0f215.webp?tr=w-1870 1870w,
                  https://ik.imagekit.io/kx65wqg4n/086b54aa7ff671f151ca7bd850b0f215.webp?tr=w-2048 2048w,
                  `}
                />
                <img src={potMakerMedium} alt="Pot Maker" />
              </picture>
            </LazyLoad>

            <h3>THE POT MAKER</h3>
          </div>

          <div className="image-container">
            <LazyLoad offset={500}>
              <picture>
                <source
                  sizes="100vw"
                  srcSet={`${rugs} 1020w,
                  ${rugsMedium} 1500w,
                  ${rugsLarge} 1920w,
                  https://ik.imagekit.io/kx65wqg4n/f56033fd8344686f54882cf889c28ab5.webp?tr=w-300 300w,
                  https://ik.imagekit.io/kx65wqg4n/f56033fd8344686f54882cf889c28ab5.webp?tr=w-880 880w,
                  https://ik.imagekit.io/kx65wqg4n/f56033fd8344686f54882cf889c28ab5.webp?tr=w-1210 1210w,
                  https://ik.imagekit.io/kx65wqg4n/f56033fd8344686f54882cf889c28ab5.webp?tr=w-1470 1470w,
                  https://ik.imagekit.io/kx65wqg4n/f56033fd8344686f54882cf889c28ab5.webp?tr=w-1680 1680w,
                  https://ik.imagekit.io/kx65wqg4n/f56033fd8344686f54882cf889c28ab5.webp?tr=w-1870 1870w,
                  https://ik.imagekit.io/kx65wqg4n/f56033fd8344686f54882cf889c28ab5.webp?tr=w-2048 2048w,
                  `}
                />
                <img src={rugsMedium} alt="Bazaars" />
              </picture>
            </LazyLoad>

            <h3>Bazaar Galore</h3>
          </div>
        </div>

        <div className="section3 outer-grid tablet-inner-grid vertical-padding-normal desktop-inner-grid">
          <PhotoWithCaption>
            <LazyLoad offset={500}>
              <picture>
                <source
                  sizes="(min-width: 1040px) calc(35.31vw - 24px), (min-width: 780px) calc(100vw - 280px), (min-width: 540px) 460px, 90.91vw"
                  srcSet={`${civ} 768w,
                ${civLarge} 1020w,
                https://ik.imagekit.io/kx65wqg4n/eaa20d8cf0af5f26215cda813ed3f15f.webp?tr=w-460 460w,
                https://ik.imagekit.io/kx65wqg4n/eaa20d8cf0af5f26215cda813ed3f15f.webp?tr=w-920 920w,
                https://ik.imagekit.io/kx65wqg4n/eaa20d8cf0af5f26215cda813ed3f15f.webp?tr=w-1040 1040w,
                https://ik.imagekit.io/kx65wqg4n/eaa20d8cf0af5f26215cda813ed3f15f.webp?tr=w-1310 1310w,
                https://ik.imagekit.io/kx65wqg4n/eaa20d8cf0af5f26215cda813ed3f15f.webp?tr=w-1530 1530w,
                https://ik.imagekit.io/kx65wqg4n/eaa20d8cf0af5f26215cda813ed3f15f.webp?tr=w-1720 1720w,
                https://ik.imagekit.io/kx65wqg4n/eaa20d8cf0af5f26215cda813ed3f15f.webp?tr=w-1890 1890w,
                https://ik.imagekit.io/kx65wqg4n/eaa20d8cf0af5f26215cda813ed3f15f.webp?tr=w-2048 2048w,
                `}
                />
                <img src={civ} alt="Civ" />
              </picture>
            </LazyLoad>
            <h3>Civilization</h3>
          </PhotoWithCaption>

          <PhotoWithCaption>
            <LazyLoad offset={500}>
              <picture>
                <source
                  sizes="(min-width: 1040px) calc(35.31vw - 24px), (min-width: 780px) calc(100vw - 280px), (min-width: 540px) 460px, 90.91vw"
                  srcSet={`${potMaker2} 768w,
                ${potMaker2Large} 1020w,
                https://ik.imagekit.io/kx65wqg4n/bfd88059cfbe680dd7aa677169cae9f5.webp?tr=w-460 460w,
                https://ik.imagekit.io/kx65wqg4n/bfd88059cfbe680dd7aa677169cae9f5.webp?tr=w-920 920w,
                https://ik.imagekit.io/kx65wqg4n/bfd88059cfbe680dd7aa677169cae9f5.webp?tr=w-1040 1040w,
                https://ik.imagekit.io/kx65wqg4n/bfd88059cfbe680dd7aa677169cae9f5.webp?tr=w-1310 1310w,
                https://ik.imagekit.io/kx65wqg4n/bfd88059cfbe680dd7aa677169cae9f5.webp?tr=w-1530 1530w,
                https://ik.imagekit.io/kx65wqg4n/bfd88059cfbe680dd7aa677169cae9f5.webp?tr=w-1720 1720w,
                https://ik.imagekit.io/kx65wqg4n/bfd88059cfbe680dd7aa677169cae9f5.webp?tr=w-1890 1890w,
                https://ik.imagekit.io/kx65wqg4n/bfd88059cfbe680dd7aa677169cae9f5.webp?tr=w-2048 2048w,
                `}
                />
                <img src={potMaker2} alt="Pot Maker 2" />
              </picture>
            </LazyLoad>
            <h3>The Pot Maker (II)</h3>
          </PhotoWithCaption>
        </div>
      </BlackSection>

      <InView
        as="div"
        className="offset-inview"
        triggerOnce={true}
        onChange={(inView, entry) =>
          setWhiteAndContactSectionVisibility(inView)
        }
      ></InView>

      <>
        {/* WHITE SECTION */}
        <div className="white-section tablet-inner-grid outer-grid vertical-padding-normal desktop-inner-grid">
          <PhotoWithCaption>
            <LazyLoad offset={500}>
              <picture>
                <source
                  sizes="(min-width: 1040px) calc(35.31vw - 24px), (min-width: 780px) calc(100vw - 280px), (min-width: 540px) 460px, 90.91vw"
                  srcSet={`${kid} 768w,
                ${kidLarge} 1020w,
                https://ik.imagekit.io/kx65wqg4n/32a6da9e00ab3c998a9f64b5959dc65e.webp?tr=w-460 460w,
                https://ik.imagekit.io/kx65wqg4n/32a6da9e00ab3c998a9f64b5959dc65e.webp?tr=w-920 920w,
                https://ik.imagekit.io/kx65wqg4n/32a6da9e00ab3c998a9f64b5959dc65e.webp?tr=w-1040 1040w,
                https://ik.imagekit.io/kx65wqg4n/32a6da9e00ab3c998a9f64b5959dc65e.webp?tr=w-1310 1310w,
                https://ik.imagekit.io/kx65wqg4n/32a6da9e00ab3c998a9f64b5959dc65e.webp?tr=w-1530 1530w,
                https://ik.imagekit.io/kx65wqg4n/32a6da9e00ab3c998a9f64b5959dc65e.webp?tr=w-1720 1720w,
                https://ik.imagekit.io/kx65wqg4n/32a6da9e00ab3c998a9f64b5959dc65e.webp?tr=w-1890 1890w,
                https://ik.imagekit.io/kx65wqg4n/32a6da9e00ab3c998a9f64b5959dc65e.webp?tr=w-2048 2048w
                `}
                />
                <img src={kid} alt="Child with stick" />
              </picture>
            </LazyLoad>
            <h3>Another World, a different experience</h3>
          </PhotoWithCaption>

          <PhotoWithCaption>
            <LazyLoad offset={500}>
              <picture>
                <source
                  sizes="(min-width: 1040px) calc(35.31vw - 24px), (min-width: 780px) calc(100vw - 280px), (min-width: 540px) 460px, 90.91vw"
                  srcSet={`${loversPerch} 768w,
                  ${loversPerchLarge} 1020w,
                  https://ik.imagekit.io/kx65wqg4n/d77bb4885c982af1b9be292ac64b04f1.webp?tr=w-460 460w,
                  https://ik.imagekit.io/kx65wqg4n/d77bb4885c982af1b9be292ac64b04f1.webp?tr=w-920 920w,
                  https://ik.imagekit.io/kx65wqg4n/d77bb4885c982af1b9be292ac64b04f1.webp?tr=w-1040 1040w,
                  https://ik.imagekit.io/kx65wqg4n/d77bb4885c982af1b9be292ac64b04f1.webp?tr=w-1310 1310w,
                  https://ik.imagekit.io/kx65wqg4n/d77bb4885c982af1b9be292ac64b04f1.webp?tr=w-1530 1530w,
                  https://ik.imagekit.io/kx65wqg4n/d77bb4885c982af1b9be292ac64b04f1.webp?tr=w-1720 1720w,
                  https://ik.imagekit.io/kx65wqg4n/d77bb4885c982af1b9be292ac64b04f1.webp?tr=w-1890 1890w,
                  https://ik.imagekit.io/kx65wqg4n/d77bb4885c982af1b9be292ac64b04f1.webp?tr=w-2048 2048w
                  `}
                />
                <img src={loversPerch} alt="Lovers Perch" />
              </picture>
            </LazyLoad>
            <h3>Lover’s Perch</h3>
          </PhotoWithCaption>
        </div>
        <div ref={dividercontainerRef}>
          <LazyLoad offset={500} height="70vh">
            <ImageDivider
              src={camel}
              srcmed={camelMedium}
              srclarge={camelLarge}
            ></ImageDivider>
          </LazyLoad>
        </div>
      </>

      {/* CONTACT SECTION */}
      <div ref={containerRef}>
        <div className="outer-grid desktop-inner-grid">
          <ContactFormMedia className="vertical-padding-large"></ContactFormMedia>
        </div>
      </div>
    </HomeWrapper>
  );
};

export default Home;
