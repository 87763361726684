import React from "react";
import styled from "styled-components/macro";

const ColourSquareContainerStyled = styled.div`
  line-height: 0;
`;

const ColourSquareContainer = (props) => {
  return (
    <ColourSquareContainerStyled
      className={`colors-container ${props.className}`}
    >
      {
        props.children /* this injects the content from wherever its used as a HOC, that way you can call the button component and put your own text */
      }
    </ColourSquareContainerStyled>
  );
};

export default ColourSquareContainer;
