import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink, NavHashLink } from "react-router-hash-link"; // This is to enable smooth scrolling to the contact form or anything else with an ID hash jump

import styled from "styled-components/macro";
import GlobalStyles from "../Styles/GlobalStyles";
import * as GlobalVariables from "../Styles/GlobalVariables";

import Logo from "../Images/logo.svg";

// The mobile menu container is both offset by a negative margin AND has 0 opacity, that way it is not visible and does not take up space on the page

const HeaderWrapper = styled.div`
  position: fixed;
  background-color: transparent;
  z-index: 99;
  transition: background-color 0.2s ease-out;
  color: white;
  width: 100%;

  // This is the outer container needed to have a smoothly working expandable mobile menu, this contains only the top parts
  .top-container {
    background: rgba(0, 0, 0, 1);
    transition: all 0.2s;

    .inner-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      z-index: 3;
      position: relative;
    }
  }

  .left-column {
    .logo {
      width: 76px;
      display: block;
      @media ${GlobalVariables.device.laptop} {
        width: 4em;
      }
    }
  }

  // Hide the links on mobile and then show them on larger screens while hiding the button
  .right-column {
    a {
      letter-spacing: 3px;
      font-size: 1.4em;

      line-height: 0.8em;
    }
    @media ${GlobalVariables.device.laptop} {
      a {
        display: inline-block;
        font-size: 1.1em;
      }
      .hamburger-react {
        display: none;
      }
    }
  }

  .mobile-menu-container {
    height: 100vh;
    margin-top: -150vh;
    opacity: 0;
    z-index: 2;
    transition: all 0.2s;
    color: white;
    background: #393939;
    padding-top: 20px;
    padding-bottom: 20px;
    &.open {
      opacity: 1;
      margin-top: 0;
    }
    a {
      display: block;
      cursor: pointer;
      margin-bottom: 4vh;
      font-size: 1.6rem;
    }
  }
`;

const Header = () => {
  // Define state variable to keep track of whether the menu is open or closed
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Define state variable to keep track of the background opacity
  const [backgroundOpacity, setBackgroundOpacity] = useState(0);

  // Define a function to toggle the menu state when the button is clicked
  const handleMenuButtonClick = () => {
    setIsMenuOpen(!isMenuOpen);

    // Toggle the body overflow to prevent scrolling when the menu is open
    isMenuOpen
      ? (document.body.style.overflow = "visible")
      : (document.body.style.overflow = "hidden");
  };

  // Define the menu link tags as a function so each one gets an OnClick (to close the mobile menu when a link has been pressed)
  function MobileLink({ to, children }) {
    return (
      <Link to={to} onClick={handleMenuButtonClick}>
        {children}
      </Link>
    );
  }

  // Use an effect to update the background opacity when the user scrolls
  useEffect(() => {
    function handleScroll() {
      // Define a scroll event handler function to calculate the opacity based on the current scroll position
      const opacity = Math.min(window.pageYOffset / 100, 1);
      setBackgroundOpacity(opacity);
    }

    // Add the scroll event listener and return a cleanup function to remove the listener when the component unmounts
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // This function is used to offset the scroll to the contact form when the contact link is clicked, it uses the react-router-hash-link package
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
    const yOffset = -100; // This is the offset, change this if you need to
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <HeaderWrapper>
      <div
        className={`top-container ${
          isMenuOpen ? "open" : ""
        } vertical-padding-small`}
      >
        <div className="outer-grid inner-container">
          <div className={`left-column`}>
            <img className="logo" src={Logo} alt="logo" />
          </div>

          <div className="right-column">
            <NavHashLink
              to={{
                pathname: "/",
                hash: "#contact-form",
                state: { fromExternalPage: true },
              }}
            >
              CONTACT
            </NavHashLink>
            {/* Render the menu button*/}
          </div>
        </div>
      </div>

      <GlobalStyles />
    </HeaderWrapper>
  );
};

export default Header;
