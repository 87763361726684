import React from "react";
import styled from "styled-components/macro";
import * as GlobalVariables from "../Styles/GlobalVariables";
const ColourSquareStyled = styled.div`
  // styles for a basic minimal rounded corner button
  height: 10px;
  width: 10px;
  background-color: ${(props) => props.color};
  display: inline-block;
  margin-right: 4px;
  &:last-of-type {
    margin-right: 0;
  }

  @media ${GlobalVariables.device.laptop} {
    height: 12px;
    width: 12px;
  }
`;

const ColourSquare = (props) => {
  return <ColourSquareStyled color={props.color}></ColourSquareStyled>;
};

export default ColourSquare;
